<template>
<div>
  <div class="container-fluid header-sub " style="">
    <div class="container-standar h-100 "><div class="breadcumb-container"></div></div>
  </div>
  <div>
    <div class="container-standar text-center">

      <div class="dk-container">

        <h1 class="d-block font-30 font-mobile-20 text-warning font-weight-6 mb-70 mt-2">Untuk Anda yang Rindu Ke Tanah Suci <br><small class="font-18 font-mobile-11">Wujudkan Impian Anda dengan Tabungan Umroh Real Travel</small></h1>
        
        <div class="ff-doa card shadow">
          <div class="row pad-20">
            <div class="col-md-12">
              <span
                class="font-24 font-weight-7 font-mobile-14 text-primary d-inline-block width-100"
              >Apakah Ini yang Anda Inginkan Untuk Bisa Ke Tanah Suci ?</span>
            </div>
          </div>

          <div class="container-fluid mb-20">
            <div class="row">
              <div class="col-6 col-lg-6 font-weight-5 font-16 text-left">
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Bisa Berangkat Ke Tanah Suci & Melakukan Umroh</p>
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Bisa Mencium Ka'bah, Mencium Hajar Aswad & Shalat di Dalam Ka'bah</p>
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Bisa Bersujud & Menangis didepan Ka'bah</p>
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Bisa Berdoa ditempat Paling Mustajab, di Multazam dan Raudhah</p> 
              </div>

              <div class="col-6 col-lg-6 font-weight-5 font-16 text-left">
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Diampuni Dosa-Dosa Masa Lalu & yang Akan Datang</p>
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Mendapatkan Pahala dengan Shalat di Masjidil Haram & Masjid Nabawi </p>
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Mendoakan Kebaikan-kebaikan Untuk Orang Tua, Saudara, & Kerabat </p>
                  <p class="btn mb-4 btn-success btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-check-circle text-white"></i> Ingin Bisa Meng umroh kan Orang Tua </p>
              </div>
            </div>
          </div>
        </div>

        <div class="ff-doa mt-4 card shadow">
          <div class="row pad-20">
            <div class="col-md-12 mb-5">
              <span
                class="font-30 font-weight-7 font-mobile-14 text-danger d-inline-block width-100"
              >TAPI .......</span>
              <span class="font-20 font-weight-7 font-mobile-14 text-danger d-inline-block width-100">Anda Masih Takut Ketika Anda Ingin Mendaftar atau Menabung ?</span>
            </div>
          </div>
          <div class="container-fluid mb-20">
            <div class="row">
              <div class="col-md-6 text-center hide-mobile">
                <img
                  class="margin-auto width-60 mb-20"
                  src="@/assets/images/ques-60.png"
                  alt
                />
              </div>
              <div class="col-md-6 font-weight-5 font-16 text-left">
                  <p class="btn mb-4 btn-danger btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-angle-right text-white"></i> Pengen Umroh Tapi Tidak Punya Dana </p>
                  <p class="btn mb-4 btn-danger btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-angle-right text-white"></i> Butuh Biaya Besar, Dananya Tidak Cukup </p>
                  <p class="btn mb-4 btn-danger btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-angle-right text-white"></i> Takut Jika Travelnya Tidak Amanah </p>
                  <p class="btn mb-4 btn-danger btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-angle-right text-white"></i> Dananya Takut Disalahgunakan </p>
                  <p class="btn mb-4 btn-danger btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-angle-right text-white"></i> Tidak Bisa Bebas Nabung Sesuai Kemampuan </p>
                  <p class="btn mb-4 btn-danger btn-block btn-md text-left font-mobile-11" href="#"><i class="fa fa-angle-right text-white"></i> Takut Tidak Transparan Dana yang Sudah di Tabungkan </p> 
              </div>
            </div>
          </div>
        </div>

        <div class="ff-doa mt-4 card shadow">
          <div class="row pad-20">
            <div class="col-12 mt-22 mb-20">
              <img
                  class="margin-auto width-10 width-mobile-30 mb-10"
                  src="@/assets/images/yay.png"
                  alt
                />
              <span class="font-20 font-weight-7 font-mobile-16 text-info d-inline-block width-100">Semua Kekhawatiran dan Ketakutan Anda,<br> Terjawab Sudah dengan Adanya Tabungan Umroh dari Real Travel</span>
              <span class="font-30 font-weight-7 font-mobile-20 mt-10 text-info d-inline-block width-100">SEMUA BISA UMROH</span>
              <span class="font-18 font-weight-7 font-mobile-14 text-warning d-inline-block width-100">Umroh yang Direncanakan dan Disesuaikan dengan Kemampuan Jamaah dalam Menabung Dana Umroh Berencananya, Baik Perhari, Perminggu maupun Perbulan</span>
            </div>
          </div>
          <div class="row mb-40 pad-20">
              <div class="col-md-12 mb-40 text-center">
                <span class="d-block margin-auto font-30 text-info mb-10 font-weight-6">6 Alasan Kenapa Anda Perlu Daftar Tabungan Umroh disini</span>
              </div>

              <div class="col-6 col-lg-4 text-center mb-5">
                <img
                  src="@/assets/images/icon/a.png"
                  alt
                  class="margin-auto"
                  width="225"
                  height="185"
                />
                <span class="d-block margin-auto font-20 font-mobile-14 text-info mb-10 font-weight-6">Tabungan Fleksibel</span>
                <span
                  class="d-block margin-auto font-14 font-mobile-12 text-dark"
                >Kamu bisa mengatur sendiri jumlah dana yang akan kamu sisihkan untuk menabung setiap bulannya sesuai dengan keinginan kamu.</span>
              </div>

              <div class="col-6 col-lg-4 text-center mb-5">
                <img
                  src="@/assets/images/icon/d.png"
                  alt
                  class="margin-auto"
                  width="225"
                  height="185"
                />
                <span
                  class="d-block margin-auto font-20 font-mobile-14 text-info mb-10 font-weight-6"
                >Dijamin Berangkat</span>
                <span
                  class="d-block margin-auto font-14 font-mobile-12 text-dark"
                >Jika tabungan kamu sudah mencapai target, berbagai paket pilihan terbaik dapat kamu pilih sesuai dengan saldo umroh yang telah kamu kumpulkan.</span>
              </div>

              <div class="col-6 col-lg-4 text-center mb-5">
                <img
                  src="@/assets/images/icon/b.png"
                  alt
                  class="margin-auto"
                  width="225"
                  height="185"
                />
                <span
                  class="d-block margin-auto font-20 font-mobile-14 text-info mb-10 font-weight-6"
                >Aman dan Amanah</span>
                <span
                  class="d-block margin-auto font-14 font-mobile-12 text-dark"
                >Dana yang kamu kumpulkan tersimpan kuat di bank BNI, tidak ada pihak manapun yang dapat menyentuhnya sampai kamu berangkat nanti.</span>
              </div>
              <div class="col-6 col-lg-4 text-center mb-5">
                <img
                  src="@/assets/images/icon/e.png"
                  alt
                  class="margin-auto"
                  width="225"
                  height="185"
                />
                <span
                  class="d-block margin-auto font-20 font-mobile-14 text-info mb-10 font-weight-6"
                >Virtual Account Jamaah</span>
                <span
                  class="d-block margin-auto font-14 font-mobile-12 text-dark"
                >Setiap jamaah yang terdaftar melalui aplikasi akan diberikan no virtual akun dari bank BNI, kapanpun dimanapun kamu bisa mengaksesnya.</span>
              </div>

              <div class="col-6 col-lg-4 text-center mb-5">
                <img
                  src="@/assets/images/icon/c.png"
                  alt
                  class="margin-auto"
                  width="225"
                  height="185"
                />
                <span class="d-block margin-auto font-20 font-mobile-14 text-info mb-10 font-weight-6">Mudah Cek Saldo</span>
                <span
                  class="d-block margin-auto font-14 font-mobile-12 text-dark"
                >Kamu bisa mengecek sendiri jumlah saldo tabungan, bisa melalui aplikasi tabungan atau melalui QR CODE yang sudah kami sediakan.</span>
              </div>
              
              <div class="col-6 col-lg-4 text-center mb-5">
                <img
                  src="@/assets/images/icon/f.png"
                  alt
                  class="margin-auto"
                  width="225"
                  height="185"
                />
                <span
                  class="d-block margin-auto font-20 font-mobile-14 text-info mb-10 font-weight-6"
                >Notifikasi via Whatsapp</span>
                <span
                  class="d-block margin-auto font-14 font-mobile-12 text-dark"
                >Setiap kali kamu menabung, kami akan mengirimkan pemberitahuan / notifikasi ke no whatsapp yang terdaftar.</span>
              </div>

          </div>
        </div>

        <div class="row mt-10 pl-3 pr-3 text-center">
          <a href="/tabungan" class="d-block width-100 font-20 bg-danger text-center pad-15 border-radius-5 cursor-pointer text-white">Daftar Sekarang Juga</a>
        </div>

        <div class="ff-doa mt-10 pad-20 card shadow">
          <div class="row">
            <div class="col-md-12 mt-22 mb-10">
              <span class="font-30 font-weight-30 text-dark text-center d-inline-block width-100">FAQ</span>
              <span class="font-16 font-weight-7 text-grey text-center d-inline-block width-100">Daftar pertanyaan yang sering diajukan</span>
            </div>
          </div>
          <div class="container-fluid">

            <div class="row">
              <div class="col-12 col-lg-6 mt-5">

                <template>
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-1 variant="light" class="text-left">Apa itu tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-1" visible accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Sebuah program merencanakan umroh, haji, dan wisata dengan sistem menabung terlebih dahulu.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-2 variant="light" class="text-left">Apa saja syarat membuka tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-2" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Untuk membuka tabungan, kamu hanya perlu menyiapkan photo <b class="text-info">KTP</b> dan No <b class="text-info">WhatsApp</b> yang masih aktif.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-3 variant="light" class="text-left">Bagaimana cara menambah saldo tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-3" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Untuk menambah saldo tabungan kamu bisa melakukan berbagai macam cara seperti <b>Bank Transfer</b>, <b>Virtual Account</b>, atau melalui <b>Minimarket</b> terdekat. Nominal tabungan bisa disesuaikan dengan kemampuan kamu bisa perhari, perpekan ataupun perbulan.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-4 variant="light" class="text-left">Jika saldonya lebih dari harga paket, bagaimana ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-4" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Jika nominal tabungan melebihi dari harga paket yang dipilih maka kelebihannya akan dikembalikan. Demikian pula sebaliknya, jika nominal tabungan kurang dari harga paket yang dipilih, maka berkewajiban menambahkan selisihnya.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-5 variant="light" class="text-left">Bagaimana jika saya mau membatalkan tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-5" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                          <ul class="list">
                            <li>1. Calon Jemaah <strong>membuat Surat Pernyataan pembatalan</strong> tabungan disertai dengan alasannya</li>
                            <li>2. Penarikan atas saldo rekening hanya dapat <strong>dilakukan di Kantor Cabang</strong> pembuka</li>
                            <li>3. Jumlah saldo yang harus disisakan setelah penarikan sebesar <strong>Rp. 500.000,- yang merupakan saldo minimum</strong></li>
                            <li>4. Saldo minimum yang tersimpan dalam Rekening Virtual dinyatakan masih <strong>tetap aktif sampai batas waktu 3 tahun</strong> dihitung dari tanggal penandatanganan Surat Pernyataan Pembatalan</li>
                            <li>5. Saldo minimum yang tersimpan dalam Rekening Virtual <strong>bisa dialihkan kepada ahli waris</strong> atau kepada keluarga yang ditunjuk, selama rekening Virtualnya masih aktif.</li>
                          </ul>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                  </div>
                </template>

              </div>
              <div class="col-12 col-lg-6 mt-5">

                <template>
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-1 variant="light" class="text-left">Berapa besar biaya awal pembukaan tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-1" visible accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Untuk mengikuti program tabungan, kamu harus melakukan setoran awal sebesar <b>Rp 500.000</b> (lima ratus ribu rupiah).</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-2 variant="light" class="text-left">Berapa besar target tabungan agar bisa berangkat ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-2" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text><ol>
                            <li>1. Umroh Reguler sebesar <b>Rp 25.000.000</b></li>
                            <li>2. Umroh VIP sebesar <b>Rp 30.000.000</b></li>
                            <li>3. Paket Wisata sebesar <b>Rp 2.000.000</b></li>
                          </ol></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-3 variant="light" class="text-left">Berapa besar setoran perbulan yang harus saya bayarkan ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-3" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Kamu bebas menentukan berapa besar setoran perbulan disesuaikan dengan kemampuan masing-masing dan rencana keberangkatan.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-4 variant="light" class="text-left">Apakah dana saya aman ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-4" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Dana kamu sangat aman tersimpan kuat di <b>Bank BNI</b>, kami sudah bekerjasama dan terintegrasi dengan Bank BNI. Sehingga dana kamu sepenuhnya ada di Bank BNI, bukan di kami, dan kamu akan mendapatkan nomor Virtual Account sendiri dan bisa melakukan pengecekan melalui barcode secara realtime.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-5 variant="light" class="text-left">Bagaimana proses pengembalian dana saya ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-5" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Proses pengembalian dana akan dilakukan setelah semua persyaratan pembatalan dipenuhi. Dana akan di kembalikan melalui metode transfer Bank.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                  </div>
                </template>

              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
</div>
</template>

<script>
import "@/assets/scss/components/_tabungan.scss";
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("SET_BG", true);
  }
};
</script>